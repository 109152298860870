
import ReportRenderer from "@/components/reports/ReportRenderer.vue";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import CommentComponent from "@/components/admin/CommentComponent.vue";
import TopHeader from "@/components/admin/TopHeader.vue";

import { CanadidateReportInfo, TemplateSchema, TypedVue } from "@/store/types";
import { V2Assessment, Benchmark, Project, ProjectTheme, Resource, V2Project } from "@/store/admin/adminTypes";
import { emptyResource } from "@/store/emptyModels";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Actions } from "@/store/admin/adminActions";

import { ColorMap } from "@/lib/charts/HighChartThemes";
import Vue from "vue";
import { Getters } from "@/store/admin/adminGetters";
import { VuexModules } from "@/store";
import { Trait } from "@/lib/consts";
import config from "@/lib/config";

const templates = require.context("@/components/reports/templates", false, /\.html\.tmpl$/); // Uses a method from the webpack require object to get all '.tmpl' files from the templates folder

const namespace = VuexModules.ADMIN;

interface AssessmentAndShortId extends V2Assessment {
  identifier: string;
  shortId: string | undefined;
}

@Component({
  components: {
    ReportRenderer,
    ComparisonRadarChartComponent,
    CommentComponent,
    TopHeader,
  },
})
export default class CandidateReport extends TypedVue {
  @Action(Actions.GET_REPORT, { namespace })
  getReportData!: (args: { assessmentId: string; isCandidate: boolean }) => Promise<CanadidateReportInfo | null>;

  @Action(Actions.GET_ORG_PROJECTS, { namespace })
  getProjects!: () => Promise<V2Project[] | null>;

  @Action(Actions.GET_CANDIDATE_ASSESSMENT_PROJECT, { namespace })
  getCandidateAssessmentProject!: (assessmentId: string) => Promise<Project | null>;

  @Action(Actions.GET_PROJECT_BENCHMARKS, { namespace })
  getProjectBenchmarks!: (projectId: string) => Promise<Benchmark[]>;

  @Action(Actions.GET_PROJECT_ASSESSMENTS, { namespace })
  getProjectAssessments!: (projectId: string) => Promise<V2Assessment[]>;

  @Action(Actions.GET_CANDIDATES_ASSESSMENT, { namespace })
  getCandidatesAssessment!: (assessmentId: string) => Promise<V2Assessment | null>;

  @Action(Actions.GET_TEMPLATE, { namespace })
  getTemplate!: (templateName: string) => Promise<TemplateSchema | null>;

  @Action(Actions.GET_LITERALS, { namespace })
  getLiterals!: ({ module, version }: { module: string; version: string }) => Promise<Resource>;

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | undefined;

  @Getter(Getters.CURRENT_PROJECT_DIVERSITY_ENABLED, { namespace })
  public diveristyEnabled!: boolean;

  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace })
  public rootBenchmarkId!: string;

  @Getter(Getters.GET_CURRENT_PROJECT_BENCHMARKS, { namespace })
  public projectBenchmarks!: Benchmark[];

  public projects: Project[] = [];

  public comparisonCandidates: ComparisonAssessmentItem[] = Vue.observable([]);
  public comparisonBenchmarks: ComparisonBenchmarkItem[] = Vue.observable([]);

  public reportLoading = true;
  public displayReport = false;
  public showNames = true;
  public candidates: AssessmentAndShortId[] = [];
  public benchmarks: Benchmark[] = [];

  public reportData: CanadidateReportInfo | null = null;
  public candidateView = false;
  public resource: Resource = emptyResource();
  public templateHTML = "";

  public nextCandidate: string | null = null;
  public prevCandidate: string | null = null;

  get projectTheme(): ProjectTheme {
    return (this.reportProject?.theme as ProjectTheme) ?? ProjectTheme.DEFAULT;
  }

  get reportProject(): Project | undefined {
    if (this.currentProject && !this.candidateView) {
      return this.toOldProject(this.currentProject);
    }

    return this.projects.find((project) => project.projectId === this.reportData?.projectId);
  }

  get comparableCandidates(): AssessmentAndShortId[] {
    return this.candidates.filter((candidate) => candidate.reportId !== undefined);
  }

  get currentCandidate(): AssessmentAndShortId | undefined {
    return this.candidates.find((candidate) => candidate.identifier === this.$route.params.id);
  }

  get backHref(): string {
    return `/admin/summary/?project=${this.reportProject?.projectId}`;
  }

  get currentCandidateHasTraitScores(): boolean {
    return Object.keys(this.currentCandidate?.report?.traitScores || {}).length > 0;
  }

  get displayComment(): boolean {
    return this.reportData && this.reportData.data.comment && (this.reportData.data.comment.title || this.reportData.data.comment.body);
  }

  get assessmentId(): string {
    return this.$route.params.id;
  }

  get traitClassRanges(): Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }> {
    const rootBenchamrk = this.projectBenchmarks.find((b) => b.benchmarkId === this.rootBenchmarkId);
    if (rootBenchamrk && rootBenchamrk.traitCutoffs) {
      return rootBenchamrk.traitCutoffs as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
    }
    return {} as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
  }

  async getTemplateHTML(templateName: string) {
    const template = templates.keys().filter((t) => t.indexOf(templateName) > -1);
    if (template) {
      if (template.length === 0) {
        const templateInfo = await this.getTemplate(templateName);

        if (templateInfo) {
          return templateInfo.template;
        } else {
          return Promise.resolve(`Template ${templateName} not found.`);
        }
      } else if (template.length > 1) {
        return Promise.resolve(`${templateName} has multiple matches.`);
      } else {
        return Promise.resolve(templates(template[0]).default);
      }
    } else {
      return Promise.resolve("Unknown Error Rendering Report.");
    }
  }

  async mounted() {
    const user = await this.$auth.getUser();

    if (!user) {
      await this.$router.push("/");
      return;
    }

    this.candidateView = user.isCandidate || this.$route.query.view === "candidate";

    const reportInfoPromise = this.getReportData({ assessmentId: this.assessmentId, isCandidate: user.isCandidate }).then((info) => {
      this.reportData = info;
    });

    let projectsPromise: Promise<any>;
    if (user.isCandidate) {
      projectsPromise = this.getCandidateAssessmentProject(this.assessmentId).then((p) => (this.projects = p ? [p] : []));
    } else {
      projectsPromise = this.getProjects().then((p) => (this.projects = (p ?? []).map((x) => this.toOldProject(x))));
    }

    await Promise.all([reportInfoPromise, projectsPromise]);

    if (!this.candidateView && !this.diveristyEnabled && this.reportData) {
      this.reportData.data.diversity = {};
    }
    this.templateHTML = await this.getTemplateHTML(`${this.reportData?.type}_${this.reportData?.version}`);
    this.resource = await this.getLiterals({ module: `report:${this.reportData?.type}`, version: (this.reportData?.version ?? "1.0").split(".")[0] });

    const benchmarksPromise = this.reportProject ? this.getProjectBenchmarks(this.reportProject.projectId) : Promise.resolve([]);
    const [benchmarks, candidateData] = await Promise.all([
      benchmarksPromise,
      user.isCandidate || !this.reportProject ? this.getCandidatesAssessment(this.assessmentId).then((x) => [x]) : this.getProjectAssessments(this.reportProject?.projectId),
    ]);

    this.candidates = Vue.observable(
      candidateData.map((entry) => ({
        ...entry,
        shortId: entry?.identifier.substring(0, 4),
        identifier: entry?.identifier || "",
        orgId: entry?.orgId || "",
        projectId: entry?.projectId || "",
        dateCreated: entry?.dateCreated || "",
        attempts: entry?.attempts || 0,
        state: entry?.state || "",
        company: entry?.company || "",
        isDeleted: entry?.isDeleted || false,
        candidate: {
          name: entry?.candidate?.name || "",
          email: entry?.candidate?.email || "",
        },
      })),
    );

    if (!user.isCandidate) {
      const completeCandidates = this.candidates.filter((x) => x.report !== undefined);
      const currentAssessmentIndx = completeCandidates.map((x) => x.identifier).indexOf(this.assessmentId);

      if (completeCandidates.length > 1 && currentAssessmentIndx > -1) {
        this.nextCandidate = completeCandidates[currentAssessmentIndx + 1 === completeCandidates.length ? 0 : currentAssessmentIndx + 1].identifier as string;
        this.prevCandidate = completeCandidates[currentAssessmentIndx - 1 < 0 ? completeCandidates.length - 1 : currentAssessmentIndx - 1].identifier as string;
      }
    }

    this.comparisonCandidates = Vue.observable<ComparisonAssessmentItem[]>(
      this.candidates.map<ComparisonAssessmentItem>((c) => ({
        data: {
          assessmentId: c.identifier,
          project: c.projectId,
          candidateName: c.candidate.name,
          isComplete: c.report !== undefined,
          readinessScore: c.report?.readiness || "default",
          traits: c.report?.traitStatuses || {},
          traitScores: c.report?.traitScores || {},
          competencies: c.report?.competencies || {},
          competencyScores: c.report?.competencyScores || {},
        },
        id: c.identifier,
        hidden: false,
        highlighted: false,
        locked: this.currentCandidate === c,
        selected: this.currentCandidate === c,
        color: localStorage.getItem(`report-chartcolor-${this.currentCandidate?.identifier ?? ""}-${c.identifier}`) ?? ColorMap[this.projectTheme][c.report?.readiness ?? "default"],
        initialColor: ColorMap[this.projectTheme][c.report?.readiness ?? "default"],
      })),
    );

    this.comparisonBenchmarks = benchmarks
      .filter((bench) => {
        if (this.candidateView) {
          if (!bench.isAdmin) {
            return bench;
          }
        } else {
          return bench;
        }
      })
      .map<ComparisonBenchmarkItem>((benchmark) => ({
        data: benchmark,
        id: benchmark.benchmarkId,
        hidden: false,
        highlighted: false,
        selected: this.rootBenchmarkId === benchmark.benchmarkId || false,
        color: localStorage.getItem(`report-chartcolor-${this.currentCandidate?.identifier ?? ""}-${benchmark.benchmarkId}`) ?? benchmark.colour,
        initialColor: benchmark.colour,
        locked: false,
      }));

    this.reportLoading = false;
    this.displayReport = true;

    if (window.userGuiding) {
      window.userGuiding.identify(this.$route.params.id, {
        reportVersion: this.reportData?.version || "Unknown",
        project: this.reportProject?.projectId || "Unknown",
        organization: this.reportProject?.organization || "Unknown",
        reportType: this.reportData?.type || "Unknown",
      });
    }
  }

  print(): void {
    this.$bvModal.hide("print-modal");
    setTimeout(() => {
      window.print();
    }, 500);
  }

  viewGuide(): void {
    if (this.rootBenchmarkId === config.CPA_BENCHMARK_ID) {
      window.open("https://assets.system-3.com/web-assets/report/cpa-report-guide.pdf");
    } else {
      window.open("https://assets.system-3.com/web-assets/report/report-guide.pdf");
    }
  }

  replayIntro(): void {
    if (window.userGuiding) {
      if (!this.candidateView) {
        window.userGuiding.previewGuide(Number(this.$config.USERGUIDING_REPORT_GUIDE_ID));
      }
      window.userGuiding.getHistory().forEach((guideId) => {
        window.userGuiding.previewGuide(Number(guideId));
      });
    }
  }

  toOldProject(project: V2Project): Project {
    return {
      projectId: project.identifier,
      dateCreated: project.dateCreated,
      organization: project.orgId,
      orgChartId: "",
      name: project.name,
      projectType: project.description || "",
      theme: project.theme,
      organizationNodes: [],
      isDeleted: false,
      features: project.features || [],
      rootBenchmarkId: project.rootBenchmarkId,
    };
  }
}
