
import { ProjectAggregates } from "@/lib/serviceTypes";
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment-mini";
import { Getter } from "vuex-class";
import { Getters } from "@/store/admin/adminGetters";
import config from "@/lib/config";

const namespace = "admin";

@Component({})
export default class ProjectVisualSummary extends TypedVue {
  @Prop()
  launchDate!: string;

  @Prop()
  aggregates!: ProjectAggregates;

  @Prop()
  diversity!: boolean;

  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace })
  public rootBenchmarkId!: string;

  get loading(): boolean {
    return this.aggregates.assessmentsSent === undefined;
  }

  get showGenericReadiness(): boolean {
    return this.rootBenchmarkId === config.CPA_BENCHMARK_ID;
  }

  get completionRate(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.assessmentsComplete || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get diversityIndex(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.diversity.diverse || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get balancedLeaders(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.readiness.balanced || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get contextualLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.contextual === 0) {
      return 0;
    }

    if (this.aggregates.readiness.niche === 0) {
      return 100 - this.balancedLeaders;
    }

    return Math.floor(((this.aggregates.readiness.contextual || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get nicheLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.niche === 0) {
      return 0;
    }

    return 100 - (this.balancedLeaders + this.contextualLeaders);
  }

  get lowAlignmentLeaders(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.readiness.lowAlignment || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get moderateAlignmentLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.moderateAlignment === 0) {
      return 0;
    }

    if (this.aggregates.readiness.moderatelyHighAlignment === 0 && this.aggregates.readiness.highAlignment === 0) {
      return 100 - this.lowAlignmentLeaders;
    }

    return Math.floor(((this.aggregates.readiness.moderatelyHighAlignment || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get moderatelyHighAlignmentLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.moderatelyHighAlignment === 0) {
      return 0;
    }

    if (this.aggregates.readiness.highAlignment === 0) {
      return 100 - (this.lowAlignmentLeaders + this.moderateAlignmentLeaders);
    }

    return Math.floor(((this.aggregates.readiness.moderatelyHighAlignment || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get highAlignmentLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.highAlignment === 0) {
      return 0;
    }

    return 100 - (this.lowAlignmentLeaders + this.moderateAlignmentLeaders + this.moderatelyHighAlignmentLeaders);
  }

  toFriendlyDate(date: string): string {
    const mDate = moment.utc(date).local();
    return mDate.format("MMMM Do, YYYY");
  }
}
