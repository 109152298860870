import Handlebars, { HelperOptions } from "handlebars";
import { TRAITS, TRAIT_GROUPINGS, COMPETENCY_STRING_MAPPING, Trait, Situation, TRAIT_CLASS_RANGES } from "@/lib/consts";

/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
function multiSort<T extends { [key: string]: T }>(array: T[], sortObject: { [key: string]: string | number } = {}) {
  const sortKeys = Object.keys(sortObject);

  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
    return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (const key in sortObject) {
    sortObject[key] = sortObject[key] === "desc" || sortObject[key] === -1 ? -1 : sortObject[key] === "skip" || sortObject[key] === 0 ? 0 : 1;
  }

  const keySort = (a: T, b: T, direction: number | null) => {
    direction = direction !== null ? direction : 1;

    if (a === b) {
      // If the values are the same, do not switch positions.
      return 0;
    }

    // If b > a, multiply by -1 to get the reverse direction.
    return a > b ? direction : -1 * direction;
  };

  return array.sort((a: T, b: T) => {
    let sorted = 0;
    let index = 0;

    // Loop until sorted (-1 or 1) or until the sort keys have been processed.
    while (sorted === 0 && index < sortKeys.length) {
      const key = sortKeys[index];

      if (key) {
        const direction = sortObject[key];

        sorted = keySort(a[key], b[key], direction as number);
        index++;
      }
    }

    return sorted;
  });
}

export function RegisterHandlebarHelpers(resources: { [key: string]: string }) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  function HandlebarsHelperStringReplace(searchText: string, replaceText: string, body: any): string {
    return body.fn(body.data.root).replaceAll(searchText, replaceText);
  }

  function HandlebarsHelperResource(this: any, ...args: any[]): string {
    const resourceString = Array.from(args).slice(0, -1).join("");
    const resourceValue = resources[resourceString];

    if (!resourceValue) {
      console.warn(`Resource '${resourceString}' not found in resources file.`);
      return "";
    }

    if (resourceValue.indexOf("{{") > -1) {
      // This resource string contains Handlebars Code
      const template = Handlebars.compile(resourceValue);
      return template(this);
    }

    return resourceValue;
  }

  function HandlebarsHelperWithKey(context: Record<string, string>, key: string, options: HelperOptions): string {
    return options.fn(context[key]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function HandlebarsHelperEachSorted(context: { [key: string]: any }, ...args: any[]): string {
    let ret = "";
    const options = Array.from(args)[args.length - 1];
    const sortKeys = Array.from(args).slice(0, args.length - 1);
    const sortBy = sortKeys.reduce((agg, item) => {
      agg[item] = "desc";
      return agg;
    }, {});
    const mappedContext = Object.keys(context).map((x, i) => ({ ...context[x], $key: x, $index: i }));
    const sortedContext = multiSort(mappedContext, sortBy);

    for (const item of sortedContext) {
      ret += options.fn(item);
    }

    return ret;
  }

  function HandlebarHelperSubstring(text: string, start: number, end: number) {
    return text.substring(start, end);
  }

  function HandlebarHelperToUpper(this: any, options: HelperOptions) {
    return options.fn(this).toUpperCase();
  }

  function HandlebarHelperIfCond(this: any, v1: any, operator: string, v2: any, options: HelperOptions) {
    const passFunc = options.fn ? options.fn : () => true;
    const failFunc = options.inverse ? options.inverse : () => false;

    switch (operator) {
      case "===":
        return v1 === v2 ? passFunc(this) : failFunc(this);
      case "!==":
        return v1 !== v2 ? passFunc(this) : failFunc(this);
      case "<":
        return v1 < v2 ? passFunc(this) : failFunc(this);
      case "<=":
        return v1 <= v2 ? passFunc(this) : failFunc(this);
      case ">":
        return v1 > v2 ? passFunc(this) : failFunc(this);
      case ">=":
        return v1 >= v2 ? passFunc(this) : failFunc(this);
      case "&&":
        return v1 && v2 ? passFunc(this) : failFunc(this);
      case "||":
        return v1 || v2 ? passFunc(this) : failFunc(this);
      default:
        return failFunc(this);
    }
  }

  function HandlebarHelperGetTraitRange(this: any, trait: Trait, scoring: any) {
    const value = scoring[trait + "_bellcurve"];

    if (!this.traitClassRanges) {
      return CalculateTraitRange(trait, scoring[trait + "_bellcurve"], TRAIT_CLASS_RANGES);
    }
    return CalculateTraitRange(trait, value, this.traitClassRanges);
  }

  function HandlebarHelperGetTraitsByRange(this: any, range: number, scoring: any) {
    const matchingTraits = [];

    for (const trait of TRAITS) {
      const score = scoring[trait + "_bellcurve"];
      if (!this.traitClassRanges) {
        this.traitClassRanges = TRAIT_CLASS_RANGES;
      }
      const traitRange = CalculateTraitRange(trait, scoring[trait + "_bellcurve"], this.traitClassRanges);
      if (traitRange === range) {
        matchingTraits.push({ name: trait, score });
      }
    }

    return matchingTraits.sort((a, b) => (a.score > b.score ? -1 : 1));
  }

  function HandlebarHelperGetTraits(this: any, scoring: any) {
    const matchingTraits = [];

    for (const trait of TRAITS) {
      const score = scoring[trait + "_bellcurve"];
      matchingTraits.push({ name: trait, score });
    }

    return matchingTraits.sort((a, b) => (a.score > b.score ? -1 : 1));
  }

  function HandlebarHelperGetSituationTraits(this: any, situation: Situation, scoring: any) {
    const matchingTraits = [];

    for (const trait of TRAITS) {
      const score = scoring[situation + "_" + trait + "_bellcurve"];
      if (score) {
        matchingTraits.push({ name: trait, score });
      }
    }

    return matchingTraits.sort((a, b) => (a.score > b.score ? -1 : 1));
  }

  function HandlebarHelperGetListLength(this: any, list: any[]) {
    return list.length;
  }

  function HandlebarHelperReverseList(this: any, list: any[]) {
    return list.reverse();
  }

  function HandlebarHelperGroupTraits(this: any, traits: { name: string; score: number }[], scoring: any, filter = "all") {
    // Given a list of traits, if all the traits of a competency grouping are present
    // in the list, replace the traits with the competency grouping name
    //
    // If 'filter' = 'all' return groupings and traits
    // If 'filter' = 'groups' return only groupings
    // If 'filter' = 'traits' return return only traits
    const traitSet = new Set(traits.map((trait) => trait.name));
    const result: { name: string; score: number }[] = [];
    if (typeof filter !== "string") {
      // Default filter to "all" if not set by args
      filter = "all";
    }

    for (const [grouping, values] of Object.entries(TRAIT_GROUPINGS)) {
      let groupingPresent = true;
      values.forEach((trait) => {
        if (!traitSet.has(trait)) {
          groupingPresent = false;
        }
      });

      if (groupingPresent && grouping !== "adaptable") {
        const score = scoring[grouping + "_bellcurve"];
        if (filter === "all" || filter === "groups") {
          result.push({ name: grouping, score });
        }
        if (filter === "all" || filter === "traits") {
          values.forEach((trait) => {
            traitSet.delete(trait);
          });
        }
      }
    }

    if (filter === "all" || filter === "traits") {
      for (const value of traitSet.values()) {
        result.push(traits.find((trait) => trait.name === value) as { name: string; score: number });
      }
    }

    return result;
  }

  function HandlebarHelperGetTraitCompetency(this: any, trait: Trait) {
    for (const [grouping, values] of Object.entries(TRAIT_GROUPINGS)) {
      if (values.includes(trait)) {
        return grouping;
      }
    }

    return "";
  }

  function HandlebarHelperGetCompetencies(this: any) {
    return Object.keys(TRAIT_GROUPINGS);
  }

  function HandlebarHelperGetCompetencyTraits(this: any, competency: string) {
    return TRAIT_GROUPINGS[competency];
  }

  function HandlebarHelperNewCompetencyString(this: any, competency: string) {
    return COMPETENCY_STRING_MAPPING[competency];
  }

  function HandlebarHelperConcat(this: any, ...args: any[]) {
    return args.slice(0, args.length - 1).join("");
  }

  function HandlebarHelperReactive(this: any, key: string, body: any) {
    return body.data.root[key];
  }

  function HandlebarHelperCreateArray(this: any, ...args: any[]) {
    return Array.prototype.slice.call(args, 0, -1);
  }

  function CalculateTraitRange(trait: Trait, traitScore: number, traitClassRanges: any) {
    // console.log(traitClassRanges);
    const traitInfo = traitClassRanges[trait];

    if (traitScore < traitInfo.lowerlow) {
      return -1;
    } else if (traitScore < traitInfo.low) {
      return 0;
    } else if (traitScore < traitInfo.high) {
      return 1;
    } else {
      return 2;
    }
  }

  Handlebars.registerHelper("WithKey", HandlebarsHelperWithKey);
  Handlebars.registerHelper("EachSorted", HandlebarsHelperEachSorted);
  Handlebars.registerHelper("TextReplace", HandlebarsHelperStringReplace);
  Handlebars.registerHelper("Resource", HandlebarsHelperResource);
  Handlebars.registerHelper("Substring", HandlebarHelperSubstring);
  Handlebars.registerHelper("ifCond", HandlebarHelperIfCond);
  Handlebars.registerHelper("ToUpper", HandlebarHelperToUpper);
  Handlebars.registerHelper("TraitRange", HandlebarHelperGetTraitRange);
  Handlebars.registerHelper("Traits", HandlebarHelperGetTraits);
  Handlebars.registerHelper("TraitsByRange", HandlebarHelperGetTraitsByRange);
  Handlebars.registerHelper("Len", HandlebarHelperGetListLength);
  Handlebars.registerHelper("GroupTraits", HandlebarHelperGroupTraits);
  Handlebars.registerHelper("Reverse", HandlebarHelperReverseList);
  Handlebars.registerHelper("TraitCompetency", HandlebarHelperGetTraitCompetency);
  Handlebars.registerHelper("Competencies", HandlebarHelperGetCompetencies);
  Handlebars.registerHelper("CompetencyTraits", HandlebarHelperGetCompetencyTraits);
  Handlebars.registerHelper("SituationTraits", HandlebarHelperGetSituationTraits);
  Handlebars.registerHelper("NewCompetencyString", HandlebarHelperNewCompetencyString);
  Handlebars.registerHelper("Array", HandlebarHelperCreateArray);
  Handlebars.registerHelper("Reactive", HandlebarHelperReactive);
  Handlebars.registerHelper("concat", HandlebarHelperConcat);
}
