
import HighCharts, { Series, SeriesArearangeOptions, SeriesAreasplineOptions } from "highcharts";
import { TypedVue } from "@/store/types";
import { RadarChartTheme, SeriesFunction, ComparisonItem } from "@/lib/charts/HighChartThemes";
import { TraitChartSeriesProvider, TraitChartOptionsProvider, TraitChartBandSeriesProvider } from "@/lib/charts/TraitChart";
import { CompetencyGroupChartOptionsProvider, CompetencyGroupChartSeriesProvider } from "@/lib/charts/CompetencyGroupChart";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

import AddComparisonItemComponent from "@/components/admin/ComparisonComponents/AddComparisonItemComponent.vue";
import ComparisonColourPickerModal from "./ComparisonColourPickerModal.vue";
import { SchemasCandidateAssessment } from "@/lib/serviceTypes";
import { Benchmark } from "@/store/admin/adminTypes";
import { Getter } from "vuex-class";
import { Getters } from "@/store/admin/adminGetters";

enum RadarChartModes {
  TRAITS_CHART = "traits",
  COMPETENCY_CHART = "competency",
}

export type ComparisonAssessmentItem = ComparisonItem<SchemasCandidateAssessment>;
export type ComparisonBenchmarkItem = ComparisonItem<Benchmark>;
export type ComparisonItemAny = ComparisonItem<SchemasCandidateAssessment | Benchmark>;
export interface ComparisonChartPreset {
  id: string;
  name: string;
  description: string;
  items: ComparisonItemAny[];
}

type ModeProviderMap = { [key: string]: SeriesFunction };
type ModeChartOptionsMap = { [key: string]: Partial<HighCharts.Options> };

@Component({
  components: {
    AddComparisonItemComponent,
    ComparisonColourPickerModal,
  },
})
export default class ComparisonRadarChartComponent extends TypedVue {
  @Prop({ default: true })
  public showHeader!: boolean;

  @Prop({ default: RadarChartModes.TRAITS_CHART })
  public mode!: RadarChartModes;

  @Prop({ default: () => [] })
  public candidates!: ComparisonAssessmentItem[];

  @Prop({ default: () => [] })
  public benchmarks!: ComparisonBenchmarkItem[];

  @Prop({ default: false })
  public showNames!: boolean;

  @Prop({ default: true })
  public allowModeSwitch!: boolean;

  @Prop()
  public theme!: string;

  @Prop({ default: "" })
  public colorUniqueKey!: string;

  @Prop()
  public presets!: ComparisonChartPreset;

  @Prop({ default: false })
  public presetEditMode!: boolean;

  // TODO: As this is a component, everything should be passed in as props. Maybe this should be a view?
  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace: "admin" })
  public rootBenchmarkId!: string;

  @Watch("candidates", { immediate: false })
  private onCandidateChange() {
    if (this.candidatesWithTraitScores.length === 0) {
      this.chartMode = RadarChartModes.COMPETENCY_CHART;
      this.hideToggle = true;
    } else {
      this.hideToggle = !this.allowModeSwitch;
      this.chartMode = RadarChartModes.TRAITS_CHART;
    }
  }

  @Ref("comparisonItemComponent")
  private comparisonItemComponent!: AddComparisonItemComponent;

  public loaded = false;
  public competencyAlertText = [];
  public hideToggle = !this.allowModeSwitch;
  public showBands = true;
  public chartMode: RadarChartModes = this.mode;
  public selectedItem: ComparisonItemAny | null = null;

  private chartObj: HighCharts.Chart | null = null;

  get candidatesWithTraitScores(): ComparisonAssessmentItem[] {
    return this.candidates.filter((item) => item.data.traitScores && Object.keys(item.data.traitScores).length > 0);
  }

  get benchmarksWithTraitScores(): ComparisonBenchmarkItem[] {
    return this.benchmarks.filter((item) => item.data.traitScores && Object.keys(item.data.traitScores).length > 0);
  }

  get candidatesForCurrentMode(): ComparisonAssessmentItem[] {
    return this.chartMode === RadarChartModes.TRAITS_CHART ? this.candidatesWithTraitScores : this.candidates;
  }

  get benchmarksForCurrentMode(): ComparisonBenchmarkItem[] {
    return this.chartMode === RadarChartModes.TRAITS_CHART ? this.benchmarksWithTraitScores : this.benchmarks;
  }

  reset() {
    this.candidates.forEach((item) => (item.selected = false));
    this.benchmarks.forEach((item) => (item.selected = false));
    this.benchmarks
      .filter((x) => x.id === this.rootBenchmarkId)
      .forEach((item) => {
        item.selected = true;
      });
    this.$emit("PresetDeSelected");
    this.comparisonItemComponent.clearPreset();
  }

  public updatePreset(newPreset: ComparisonChartPreset) {
    this.$emit("UpdatePreset", newPreset);
  }

  public savePreset(newPreset: ComparisonChartPreset) {
    this.$emit("SavePreset", newPreset);
  }

  public removePreset(preset: ComparisonChartPreset) {
    this.$emit("RemovePreset", preset);
  }

  public presetSelected(preset: ComparisonChartPreset) {
    this.$emit("PresetSelected", preset);
  }

  public presetDeSelected() {
    this.$emit("PresetDeSelected");
  }

  public redrawChart(): void {
    if (this.chartObj) {
      this.chartObj.redraw();
    }
  }

  public ToggleHideItem({ item, show }: { item: ComparisonItemAny; show: boolean }): void {
    if (this.chartObj) {
      const series = this.chartObj.series.find((series) => (series.options as any).data[0].comparisonItem.id === item.id);

      if (series) {
        series.setVisible(show);
      }
    }
  }

  public chartCallback(chart: HighCharts.Chart): void {
    this.chartObj = chart;
  }

  public findComparisonItemById(id: string) {
    return this.candidates.find((x) => x.id === id) || this.benchmarks.find((x) => x.id === id);
  }

  public setSelectedItem(item: ComparisonItemAny) {
    this.selectedItem = item;
  }

  public clearItemHighlights() {
    this.selectedItem = null;
  }

  public mounted() {
    this.$nextTick(() => {
      this.showBands = false;
    });
  }

  public seriesOnMouseOver(component: this) {
    return function (this: Series & { userOptions: { comparisonObject: ComparisonItemAny } }) {
      component.clearItemHighlights();

      const seriesObject = component.findComparisonItemById(this.userOptions.comparisonObject.id);

      if (seriesObject) {
        component.setSelectedItem(seriesObject);
      }
    };
  }

  private BandChartSeriesProvider = TraitChartBandSeriesProvider({
    showNames: this.showNames,
    theme: this.theme,
  });

  private ChartSeriesModeProviders: ModeProviderMap = {
    [RadarChartModes.TRAITS_CHART]: TraitChartSeriesProvider({
      showNames: this.showNames,
      theme: this.theme,
      onMouseOver: this.seriesOnMouseOver(this),
      onMouseOut: this.clearItemHighlights,
    }),
    [RadarChartModes.COMPETENCY_CHART]: CompetencyGroupChartSeriesProvider({
      showNames: this.showNames,
      theme: this.theme,
      onMouseOver: this.seriesOnMouseOver(this),
      onMouseOut: this.clearItemHighlights,
    }),
  };

  private ChartModeOptions: ModeChartOptionsMap = {
    [RadarChartModes.TRAITS_CHART]: TraitChartOptionsProvider({ showNames: this.showNames, theme: this.theme, resourceLookup: (key) => this.$t(key).toString(), onMouseOut: this.clearItemHighlights }),
    [RadarChartModes.COMPETENCY_CHART]: CompetencyGroupChartOptionsProvider({ showNames: this.showNames, theme: this.theme, resourceLookup: (key) => this.$t(key).toString(), onMouseOut: this.clearItemHighlights }),
  };

  get selectedCandidates(): ComparisonAssessmentItem[] {
    return this.candidatesForCurrentMode.filter((item) => item.selected);
  }

  get selectedBenchmarks(): ComparisonBenchmarkItem[] {
    return this.benchmarksForCurrentMode.filter((item) => item.selected);
  }

  get seriesData(): (SeriesAreasplineOptions | SeriesArearangeOptions)[] {
    const candidates = this.selectedCandidates.map((item) =>
      this.ChartSeriesModeProviders[this.chartMode]<SchemasCandidateAssessment>(item, (item) => (this.showNames ? item.candidateName : item.assessmentId.substring(0, 4).toUpperCase())),
    );
    const benchmarks = this.selectedBenchmarks.map((item) => this.ChartSeriesModeProviders[this.chartMode]<Benchmark>(item, (item) => item.name));
    const bandedBenchmarks =
      this.chartMode === RadarChartModes.TRAITS_CHART && this.showBands
        ? this.selectedBenchmarks.filter((b) => b.data.traitRanges && Object.keys(b.data.traitRanges).length > 0).map((item) => this.BandChartSeriesProvider<Benchmark>(item, (item) => item.name))
        : [];
    return [...candidates, ...benchmarks, ...bandedBenchmarks];
  }

  get highchartOptions(): HighCharts.Options {
    return {
      ...RadarChartTheme,
      ...this.ChartModeOptions[this.chartMode],
      series: this.seriesData,
    };
  }
}
